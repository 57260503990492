import React from "react";

import "../styles/menufrontpage.css";

const MenuFrontPage = () => {
  return (
    <div>
      <div className="menuFrontPageContainer">
        <div className="menuTexture"></div>
        <h2 className="menuFrontPageHeading">Menu</h2>
        <p className="menuFrontPageSlogan">Our Café, Your Home!</p>
      </div>
    </div>
  );
};

export default MenuFrontPage;
