import React, { useState } from "react";
import { Fade } from "react-awesome-reveal";

import CoffeeMenu from "../components/CoffeeMenu";
import MenuFrontPage from "../components/MenuFrontPage";
import DrinkMenu from "../components/DrinkMenu";
import FullMenuModal from "../components/modals/FullMenuModal";

import "../styles/menu.css";

const Menu = ({ setRef }) => {
  const [showMenuFrontPage, setShowMenuFrontPage] = useState(true);
  const [showCoffeeMenu, setShowCoffeeMwenu] = useState(false);
  const [showDrinkMenu, setShowDrinkMenu] = useState(false);
  const [showFullMenu, setShowFullMenu] = useState(false);

  const handleCoffeeMenuClick = () => {
    setShowMenuFrontPage(false);
    setShowCoffeeMwenu(true);
    setShowDrinkMenu(false);
  };

  const handleDrinkMenuClick = () => {
    setShowMenuFrontPage(false);
    setShowCoffeeMwenu(false);
    setShowDrinkMenu(true);
  };

  const handleMenuClick = () => {
    setShowMenuFrontPage(true);
    setShowCoffeeMwenu(false);
    setShowDrinkMenu(false);
  };

  const openFullMenuModal = () => {
    setShowFullMenu(true);
  };

  const closeFullMenuModal = () => {
    setShowFullMenu(false);
  };

  return (
    <div className="menuBgColor">
      <div className="menuContainer" ref={setRef} id="menu">
        <div className="menuHeadingContainer">
          <Fade duration={3000}>
            <h2 className="menuHeading">Menu</h2>
          </Fade>
        </div>

        <div className="menuBlurOverlay"></div>

        <div className="menuContentContainer">
          <div className="menuContentRight">
            <Fade duration={3000}>
              {showMenuFrontPage && <MenuFrontPage />}
              {showCoffeeMenu && <CoffeeMenu />}
              {showDrinkMenu && <DrinkMenu />}
            </Fade>
          </div>
          <div className="menuContentLeft">
            <Fade duration={3000}>
              <div>
                <button className="MenuBtn" onClick={handleMenuClick}>
                  <div className="menuBtnTexture"></div>
                  <h2 className="MenuBtnText">Menu</h2>
                </button>
              </div>
              <div>
                <button className="MenuBtn" onClick={handleCoffeeMenuClick}>
                  <div className="menuBtnTexture"></div>
                  <h2 className="MenuBtnText">Coffee</h2>
                </button>
              </div>
              <div>
                <button className="MenuBtn" onClick={handleDrinkMenuClick}>
                  <div className="menuBtnTexture"></div>

                  <h2 className="MenuBtnText">Drinks</h2>
                </button>
              </div>
              <div>
                <button className="MenuBtn" onClick={openFullMenuModal}>
                  <div className="menuBtnTexture"></div>

                  <h2 className="MenuBtnText">Full Menu</h2>
                </button>
              </div>
            </Fade>
          </div>
          {showFullMenu && <FullMenuModal closeModal={closeFullMenuModal} />}
        </div>
      </div>
    </div>
  );
};

export default Menu;
