import React, { useEffect, useState } from "react";

import "../styles/instagramfeed.css";

const Instagram = () => {
  const [feed, setFeed] = useState([]);
  const [clickedPost, setClickedPost] = useState(false);

  useEffect(() => {
    const getInstagramData = async () => {
      try {
        const response = await fetch(
          "https://askanything-api.vercel.app/api/cafedesartistesInstagram"
        );
        const data = await response.json();
        console.log(data); // Log the data to inspect its structure
        setFeed(data.data.slice(0, 9));
      } catch (error) {
        console.log("Error fetching data:", error);
      }
    };

    getInstagramData();
  }, []);

  const handlePostClick = (postId) => {
    setClickedPost(postId);
  };

  const handleClosedPost = () => {
    setClickedPost(false);
  };

  return (
    <div className="instagramfeed">
      {feed &&
        feed.map((image) => (
          <div
            className="instaPostsContainer"
            key={image.id}
            onClick={() => handlePostClick(image.id)}
          >
            <img
              className="instaPosts"
              src={image.media_url}
              alt={image.caption}
            />
          </div>
        ))}
      {clickedPost && (
        <div className="instaModal" onClick={handleClosedPost}>
          <img
            className="instaModalImage"
            src={feed.find((image) => image.id === clickedPost).media_url}
            alt={feed.find((image) => image.id === clickedPost).caption}
          />
        </div>
      )}
    </div>
  );
};

export default Instagram;
