import React from "react";
import { useInView } from "react-intersection-observer";

import Navbar from "./components/Navbar";
import Home from "./pages/Home";
import Menu from "./pages/Menu";
import Events from "./pages/Events";
import Gallery from "./pages/Gallery";
import About from "./pages/About";

import "./styles/app.css";

function App() {
  const threshold = 0.3;

  const [setHomeRef, homeInView, homeEntry] = useInView({ threshold });
  const [setMenuRef, menuInView, menuEntry] = useInView({ threshold });
  const [setEventsRef, eventsInView, eventsEntry] = useInView({ threshold });
  const [setGalleryRef, galleryInView, galleryEntry] = useInView({ threshold });
  const [setAboutRef, aboutInView, aboutEntry] = useInView({ threshold });

  return (
    <div className="snap-parent">
      <Navbar
        view={{
          homeInView,
          menuInView,
          eventsInView,
          galleryInView,
          aboutInView,
        }}
        entry={{ homeEntry, menuEntry, eventsEntry, galleryEntry, aboutEntry }}
      />

      <div className="snap-child">
        <Home setRef={setHomeRef} inView={homeInView} />
      </div>
      <div className="snap-child">
        <Menu setRef={setMenuRef} inView={menuInView} />
      </div>
      <div className="snap-child">
        <Events setRef={setEventsRef} inView={eventsInView} />
      </div>
      <div className="snap-child">
        <Gallery setRef={setGalleryRef} inView={galleryInView} />
      </div>
      <div className="snap-child">
        <About setRef={setAboutRef} inView={aboutInView} />
      </div>
    </div>
  );
}

export default App;
