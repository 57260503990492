import React from "react";
import { Fade } from "react-awesome-reveal";

import "../styles/coffeemenu.css";

import Caffe from "../images/Coffee/espresso.jpg";
import Cappuccino from "../images/Coffee/cappuccino.jpg";
import Espressino from "../images/Coffee/espressino.jpg";
import Macchiato from "../images/Coffee/macchiato.jpg";
import LatteMacchiato from "../images/Coffee/lattemacchiato.jpg";

const CoffeeMenu = () => {
  return (
    <Fade duration={1500}>
      <div className="coffeeMenuContainer">
        <div className="coffeeMenuTexture"></div>
        <h2 className="coffeeMenuHeading">Our Amazing Itaian Coffee</h2>
        <p className="coffeeMenuParagraph"> Our Top 5 Favorite Coffees</p>
        <ol className="coffeeMenu">
          <li className="coffeeItems">
            <div className="coffeeTitlePic">
              <img src={Caffe} alt="Caffé" className="coffeePictures" />
            </div>
            <h4>Espresso 1€</h4>
          </li>
          <li className="coffeeItems">
            <div className="coffeeTitlePic">
              <img
                src={Cappuccino}
                alt="Ristretto"
                className="coffeePictures"
              />
            </div>
            <h4>Cappuccino 1.30€</h4>
          </li>
          <li className="coffeeItems">
            <div className="coffeeTitlePic">
              <img src={Espressino} alt="Lungo" className="coffeePictures" />
            </div>
            <h4>Espressino 1€</h4>
          </li>
          <li className="coffeeItems">
            <div className="coffeeTitlePic">
              <img
                src={Macchiato}
                alt="Caffè doppio"
                className="coffeePictures"
              />
            </div>
            <h4>Cafe Macchiato 1€</h4>
          </li>
          <li className="coffeeItems">
            <div className="coffeeTitlePic">
              <img
                src={LatteMacchiato}
                alt="Caffè decaffeinato"
                className="coffeePictures"
              />
            </div>
            <h4>Latte Macchiato 1.50€</h4>
          </li>
        </ol>
      </div>
    </Fade>
  );
};

export default CoffeeMenu;
