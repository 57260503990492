import React from "react";
import Instagram from "../components/Instagram";
import { Fade } from "react-awesome-reveal";

import "../styles/gallery.css";

const Gallery = ({ setRef }) => {
  return (
    <div className="galleryBgColor">
      <div className="galleryContainer" ref={setRef} id="gallery">
        <div className="galleryBlurOverlay"></div>

        <div className="galleryHeadingContainer ">
          <Fade duration={3000}>
            <div className="galleryHeading">
              <h2 className="galleryTitle">Gallery</h2>
              <p className="galleryParagraph">Our Amazing Journey</p>
            </div>
          </Fade>
          <div className="instagramContainer">
            <Fade duration={3000}>
              <Instagram />
            </Fade>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Gallery;

/// TA BORT INSTAGRAM ON DET INTE FUNGERAR!!!

// const instagramAccessToken = String(process.env.INSTAGRAM_KEY);

// export const getStaticProps = async () => {
//   const url = `https://graph.instagram.com/me/media?fields=id,caption,media_url,timestamp,media_type,permalinkm&access_token=${instagramAccessToken}`;
//   const data = await fetch(url);
//   const feed = await data.json();

//   console.log(feed);
// };
