import React from "react";

import "./styles/familyhistorymodal.css";

const FamilyHistoryModal = ({ closeModal }) => {
  return (
    <div className="familyHistoryModal">
      <div className="familyHistoryModalContent">
        <span className="familyHistoryModalclose" onClick={closeModal}>
          &times;
        </span>
        <div className="familyHistoryContainer">
          <h1 className="familyHistoryTitle">Family History</h1>
          <p className="familyHistoryContent">
            In 1909 in a small town in Northern Italy, Premia to be exact, Luigi
            Bernardi and Cornelia Barbetta were married. This is where our
            Italian family began. From a very early age, I have always wanted to
            live in Italy, and in 2021 we made the leap to begin the process.
            Yes, we! My daughter Magin and her husband Dylan were definitely on
            board to go with me. So, in 2022, just 6 months after our little
            Rosemary was born, we left America for Italy.
          </p>
          <p className="familyHistoryContent">
            Arriving in Italy was incredibly joyous and terrifying at the same
            time. Having a dream and doing it are two completely different
            things. We bought a house in Mafalda and nine months later we bought
            the Café in our town's piazza! Café des Artistes became ours on July
            4th! It was our own personal independence day! We couldn’t be
            happier to be a part of this incredible community. The people of
            Mafalda are amazing, and have accepted us like family. We have
            Eugenio to thank for having faith in us and selling us the bar he
            has owned and worked in for 35 years. He helped us build trust with
            our locals to be able to do this adventure!
          </p>
          <p className="familyHistoryContent">
            The cafe has been part of this community for over 50 years, possibly
            even longer. We just aren’t sure. It has had multiple owners and a
            few locations in the piazza. Eugenio was just 24 when he made the
            purchase.
          </p>
        </div>
      </div>
    </div>
  );
};
export default FamilyHistoryModal;
