import React from "react";

import "./styles/fullmenumodal.css";

import pageOne from "../../images/FullMenu/1.jpg";
import pageTwo from "../../images/FullMenu/2.jpg";
import pageThree from "../../images/FullMenu/3.jpg";

const FullMenuModal = ({ closeModal }) => {
  return (
    <div className="fullMenuModal" onClick={closeModal}>
      <span className="fullMenuModalclose" onClick={closeModal}>
        &times;
      </span>
      <div className="fullMenuContainer">
        <img className="menuPages" src={pageOne} alt="Menu page one" />
        <img className="menuPages" src={pageTwo} alt="Menu page two" />
        <img className="menuPages" src={pageThree} alt="Menu page three" />
      </div>
    </div>
  );
};
export default FullMenuModal;
