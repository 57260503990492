import { useEffect, useRef, useState } from "react";
import FamilyHistoryModal from "../components/modals/FamilyHistoryModal";
import CafeHistoryModal from "../components/modals/CafeHistoryModal";
import cafeHistoryImg from "../images/AboutPage/cafedesartistes.jpg";
import familyHistoryImg from "../images/AboutPage/cafedesartistesfamily.jpg";
import { AiFillLeftCircle } from "react-icons/ai";
import { AiFillRightCircle } from "react-icons/ai";
import { Fade } from "react-awesome-reveal";
import "../styles/about.css";

const About = ({ setRef }) => {
  const containerRef = useRef(null);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(true);

  const [showFamilyHistoryModal, setShowFamilyHistoryModal] = useState(false);
  const [showCafeHistoryModal, setShowCafeHistoryModal] = useState(false);

  useEffect(() => {
    const container = containerRef.current;

    const handleScroll = () => {
      const { scrollLeft, scrollWidth, clientWidth } = container;

      setShowLeftArrow(scrollLeft > 0);
      setShowRightArrow(scrollLeft < scrollWidth - clientWidth);
    };

    container.addEventListener("scroll", handleScroll);

    return () => {
      container.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScrollLeft = () => {
    const container = containerRef.current;
    const scrollAmount = container.clientWidth * 1.5;
    container.scrollBy({
      left: -scrollAmount,
      behavior: "smooth",
    });
  };

  const handleScrollRight = () => {
    const container = containerRef.current;
    const scrollAmount = container.clientWidth * 1.5;
    container.scrollBy({
      left: scrollAmount,
      behavior: "smooth",
    });
  };

  const openFamilyHistoryModal = () => {
    setShowFamilyHistoryModal(true);
  };

  const closeFamilyHistoryModal = () => {
    setShowFamilyHistoryModal(false);
  };

  const openCafeHistoryModal = () => {
    setShowCafeHistoryModal(true);
  };

  const closeCafeHistoryModal = () => {
    setShowCafeHistoryModal(false);
  };

  return (
    <div className="aboutBgColor">
      <div className="aboutContainer" ref={setRef} id="about">
        <div className="aboutBlurOverlay"></div>

        <div className="aboutHeadingContainer ">
          <Fade duration={3000}>
            <div className="aboutHeading">
              <h2 className="aboutTitle">About</h2>
              <p className="aboutParagraph">Us And Our Story</p>
            </div>
          </Fade>
        </div>

        <div className="aboutContentContainer" ref={containerRef}>
          <div className="aboutContentFamily">
            <Fade duration={3000}>
              <div className="aboutContentFamilyContainer">
                <h2 className="aboutTitleFamily">The Family History</h2>
                <div
                  className="familyHistoryImgContainer"
                  onClick={openFamilyHistoryModal}
                >
                  <img
                    className="familyHistoryImg"
                    src={familyHistoryImg}
                    alt="Family History"
                  />
                </div>

                <p className="aboutHistoryContent">
                  In 1909 in a small town in Northern Italy, Premia to be exact,
                  Luigi Bernardi and Cornelia Barbetta were married. This is
                  where our Italian family began.
                </p>
              </div>
            </Fade>
          </div>
          <div className="aboutContentHistory">
            <Fade duration={3000}>
              <div className="aboutContentHistoryContainer">
                <h2 className="aboutTitleCafe">
                  The History of Cafe`des Artistes
                </h2>
                <div
                  className="cafeHistoryImgContainer"
                  onClick={openCafeHistoryModal}
                >
                  <img
                    className="cafeHistoryImg"
                    src={cafeHistoryImg}
                    alt="Cafe History"
                  />
                </div>
                <p className="aboutHistoryContent">COMING SOON!</p>
              </div>
            </Fade>
          </div>

          {showFamilyHistoryModal && (
            <FamilyHistoryModal closeModal={closeFamilyHistoryModal} />
          )}

          {showCafeHistoryModal && (
            <CafeHistoryModal closeModal={closeCafeHistoryModal} />
          )}

          {showLeftArrow && (
            <div className="scrollArrow left" onClick={handleScrollLeft}>
              <button className="aboutArrowBtn">
                <AiFillLeftCircle />
              </button>
            </div>
          )}

          {showRightArrow && (
            <div className="scrollArrow right" onClick={handleScrollRight}>
              <button className="aboutArrowBtn">
                <AiFillRightCircle />
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default About;
