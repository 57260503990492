import React, { useEffect, useRef } from "react";
import { useInView } from "react-intersection-observer";
import { Fade } from "react-awesome-reveal";

import "../styles/home.css";

const Home = ({ setRef }) => {
  return (
    <div className="homeContainer" ref={setRef} id="home">
      <div className="homeOverlay">
        <div className="contentContainer">
          <Fade duration={3000}>
            <div className="contentLeft">
              <h2 className="welcomeText">Welcome</h2>

              <p className="welcomeTextSlogan">Our Cafe, Your Home</p>
            </div>
          </Fade>
        </div>
      </div>
    </div>
  );
};

export default Home;
