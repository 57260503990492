import React from "react";

import "./styles/cafehistorymodal.css";

const CafeHistoryModal = ({ closeModal }) => {
  return (
    <div className="cafeHistoryModal">
      <div className="cafeHistoryModalContent">
        <span className="cafeHistoryModalclose" onClick={closeModal}>
          &times;
        </span>
        <div className="cafeHistoryContainer">
          <h1 className="cafeHistoryTitle">Café History</h1>
          <p className="cafeHistoryContent">COMING SOON!</p>
          <p className="cafeHistoryContent"></p>
          <p className="cafeHistoryContent"></p>
        </div>
      </div>
    </div>
  );
};
export default CafeHistoryModal;
