import React from "react";
import { Fade } from "react-awesome-reveal";

// import EventsComponent from "../components/EventsComponent";

import anniversario from "../images/EventsPage/anniversario.jpg";
import "../styles/events.css";

const Events = ({ setRef }) => {
  return (
    <div className="eventsBgColor">
      <div className="eventsContainer" ref={setRef} id="events">
        <div className="blurOverlay"></div>

        <div className="eventsContentLeft">
          <Fade duration={3000}>
            <div className="eventsHeading">
              <h2 className="eventsTitle">Upcoming</h2>
              <h3 className="eventsTitle1">EVENTS</h3>
            </div>
          </Fade>
          {/* <EventsComponent /> */}
        </div>
        <Fade duration={3000}>
          <div className="eventsContentRight">
            <img className="eventPicture" src={anniversario} alt="event" />
          </div>
        </Fade>
      </div>
    </div>
  );
};

export default Events;
