import React from "react";

import { HiHome } from "react-icons/hi";
import { MdRestaurantMenu } from "react-icons/md";
import { BiParty } from "react-icons/bi";
import { AiFillQuestionCircle } from "react-icons/ai";
import { FaCameraRetro } from "react-icons/fa";

import "../styles/navbar.css";
import Logo from "../images/navbar/Logo.png";

const Navbar = ({ view, entry }) => {
  const scrollTo = (event, el) => {
    event.preventDefault();
    el.scrollIntoView({ behavior: "smooth", block: "start" });
  };

  return (
    <header className="headerContainer">
      <div className="logo">
        <img className="logo1" src={Logo} alt="" width={150} />
      </div>
      <nav className="navigationContainer">
        <div id="navbar">
          <a
            href="#home"
            onClick={(e) => scrollTo(e, entry.homeEntry.target)}
            className={view.homeInView ? "active" : ""}
          >
            <HiHome className="icon" />
            <span>Home</span>
          </a>

          <a
            href="#menu"
            onClick={(e) => scrollTo(e, entry.menuEntry.target)}
            className={view.menuInView ? "active" : ""}
          >
            <MdRestaurantMenu className="icon" />
            <span>Menu</span>
          </a>
          <a
            href="#events"
            onClick={(e) => scrollTo(e, entry.eventsEntry.target)}
            className={view.eventsInView ? "active" : ""}
          >
            <BiParty className="icon" />
            <span>Events</span>
          </a>
          <a
            href="#gallery"
            onClick={(e) => scrollTo(e, entry.galleryEntry.target)}
            className={view.galleryInView ? "active" : ""}
          >
            <FaCameraRetro className="icon" />
            <span>Gallery</span>
          </a>
          <a
            href="#about"
            onClick={(e) => scrollTo(e, entry.aboutEntry.target)}
            className={view.aboutInView ? "active" : ""}
          >
            <AiFillQuestionCircle className="icon" />
            <span>About</span>
          </a>
        </div>
      </nav>
    </header>
  );
};

export default Navbar;
