import React from "react";
import { Fade } from "react-awesome-reveal";

import "../styles/drinkmenu.css";

import AperolSpritz from "../images/Drinks/aperolspritz.jpg";
import CampariSpritz from "../images/Drinks/camparispritz.jpg";
import GinMare from "../images/Drinks/ginmare.jpg";
import CampariSoda from "../images/Drinks/camparisoda.jpg";
import AperolSoda from "../images/Drinks/aperolsoda.jpg";

const DrinkMenu = () => {
  return (
    <Fade duration={1500}>
      <div className="drinkMenuContainer">
        <div className="drinkMenuTexture"></div>
        <h2 className="drinkMenuHeading">Our Delious Drinks</h2>
        <p className="drinkMenuParagraph">
          The Bartenders Top 5 Recomendations
        </p>
        <ol className="drinkMenu">
          <li className="drinkItems">
            <div className="drinkTitlePic">
              <img
                src={AperolSpritz}
                alt="Aperitivo"
                className="drinkPictures"
              />
            </div>
            <h4>Aperol Spritz 4€</h4>
          </li>
          <li className="drinkItems">
            <div className="drinkTitlePic">
              <img
                src={CampariSpritz}
                alt="Negroni"
                className="drinkPictures"
              />
            </div>
            <h4>Campari Spritz 4€</h4>
          </li>
          <li className="drinkItems">
            <div className="drinkTitlePic">
              <img src={AperolSoda} alt="Campari" className="drinkPictures" />
            </div>
            <h4>Aperol Soda 2€</h4>
          </li>
          <li className="drinkItems">
            <div className="drinkTitlePic">
              <img
                src={CampariSoda}
                alt="Americano"
                className="drinkPictures"
              />
            </div>
            <h4>Campari Soda 2€</h4>
          </li>
          <li className="drinkItems">
            <div className="drinkTitlePic">
              <img src={GinMare} alt="Bellini" className="drinkPictures" />
            </div>
            <h4>Gin Mare 6€</h4>
          </li>
        </ol>
      </div>
    </Fade>
  );
};

export default DrinkMenu;
